import * as React from "react"
import Layout from "../views/layouts/layout"
import JobDefault from "../views/templates/template.job.default"
import {graphql} from "gatsby";



export default function JobController(props) {
    const job = props.data.job
    return (
        <Layout node={job}>
            <JobDefault job={job}/>
        </Layout>
    )
}




export const jobQuery = (graphql`
  query JobById(
    $id: String!
  ) {  
    job: wpJob(id: { eq: $id }) {
      id
      title
      template {
        templateName
      }
      language{
        code
      }
    seo {
        canonical
        metaDescription
        metaTitle
        nofollow
        noindex
        h1
      }
        uri
      ...JobDefaultInformation
    }
  }
`)