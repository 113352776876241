import {Img, Box, ButtonGroup, VStack} from "@chakra-ui/react";
import { Formik, ErrorMessage,Field } from "formik";
import axios from "axios"
import { InputControl, SubmitButton} from "formik-chakra-ui";
import * as React from "react";
import * as Yup from "yup";
import arrow from "/src/images/arrow.svg";
import '/src/css/radio.css'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = (values,{ setSubmitting, setStatus, resetForm }) => {

    sleep(300).then(() => {
        setStatus();
        const formData = new FormData();
        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        formData.append('template', 'recruitment');
        formData.append('subject', 'Aplikacja ze strony CyrekFinance.com');
        formData.append('mailerTo', process.env.GATSBY_EMAIL_ADDRESS);
        formData.append('mailerFrom', 'biuro@cyrekfinance.com');
        formData.append('mailerFromName', 'Dział HR z Cyrek Digital');

        axios({
            method: "POST",
            url: process.env.GATSBY_API_URL + '?module=mailer',
            data: formData,
            header: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            resetForm();
            setSubmitting(false);
            document.getElementById('jobForm').style.display = 'none';
            document.getElementById('required').style.display = 'none';
            setStatus(<Box background={'#008F47'} p={'10px'} color={'#FFF'}>Dziękujemy za aplikowanie</Box>);
        }).catch(err => {
            console.error({err});
        });
    });
};

const FILE_SIZE =  10 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.oasis.opendocument.text"
];

const validationSchema = Yup.object({
    fullName: Yup.string().required('Prosimy podać swoje imię i nazwisko'),
    phone: Yup.number().typeError('Zaakceptujemy tylko cyfry').required('Prosimy podać swój numer telefonu'),
    email: Yup.string().email('E-mail jest niepoprawny.').required('E-mail jest wymagany.'),
    agreement: Yup.boolean().oneOf([true],'Zgoda jest wymagana'),
    file: Yup.mixed()
     .required('Plik jest wymagany')
     .test(
         "fileSize",
         "Plik jest za duży. Maksymalnie "+FILE_SIZE/1024/1024+'MB',
         value => value && value.size <= FILE_SIZE
     )
     .test(
         "fileFormat",
         "Niewspierany format pliku",
         value => value && SUPPORTED_FORMATS.includes(value.type)
     )
});

const JobForm = (props) => {
    const initialValues = {
        fullName: "",
        email: "",
        phone: "",
        file: null,
        agreement: false,
        position: props.job
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, values,setFieldValue, errors,status }) => (
                <Box
                    as="form"
                    onSubmit={handleSubmit}
                >
                    {status}
                    <VStack spacing={6} align="flex-start" transition = 'all 0.3s' id={'jobForm'}
                            sx={{
                                '.chakra-form__required-indicator' : {display: 'none'},
                                '.chakra-form__error-message' : { fontSize: '11px', mt :'0px'},
                                '.abs label' : { position: 'absolute', top: '8px', pl: '20px', fontSize: '14px'},
                                '.abs label::before' : {
                                    content: "'■'",
                                    color : '#9BCD66',
                                    fontWeight : 'bold',
                                    fontSize: '7px',
                                    mr: '10px',
                                    ml: '-20px',
                                    display: 'inline',
                                    position: 'absolute'
                                },
                                'label.isFilled, .abs label[data-focus], .abs label[data-invalid]' : { top: '-20px'},
                                'input[aria-invalid="true"]' : { border: '0', borderRadius: '0', borderBottom: '2px solid red', boxShadow: 'none'},
                                '.abs label[data-invalid] input' : { border: '0', borderBottom: '2px solid red', boxShadow: 'none'},
                                'input, input:active, input:hover, input:focus, input:focus-visible' : {border: '0', borderRadius: '0', borderBottom: '2px solid #008F47', boxShadow: 'none'},
                                'input[type=checkbox]' : {border: '0', borderRadius: '0'},
                                'a' : { fontWeight: 'bold'}
                            }}>

                            <Box className="form-group" style={{ width: '100%', borderBottom: '2px solid #008F47', color: 'red', fontSize: '11px'}}
                            sx={{
                                '&':{color: 'red'},
                                '& label' : {color: 'black !important', fontSize: '14px !important'}
                                }}>
                                <label htmlFor="file" style={{ cursor: 'pointer'}} color={'#336699'} >Kliknij, aby dodać plik (PDF, DOC, DOCX)</label>
                                <input id="file"
                                       name="file"
                                       type="file"
                                       accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                                       onChange={(event) => {
                                            let spot = document.getElementById("fileSpot")
                                            if (event.currentTarget.files[0]){
                                                spot.innerText = event.currentTarget.files[0].name;
                                            }
                                            setFieldValue("file", event.currentTarget.files[0]);
                                       }}
                                       className="form-control"
                                       style={{display: 'none'}}/>
                                <Box id="fileSpot"></Box>
                                <ErrorMessage name="file" style={{fontSize:'11px', color:'#E53E3E'}}/>
                            </Box>
                            <InputControl name="fullName" label="Imię i nazwisko" className={'abs'} id={'fullNameGroup'}
                                          onBlur = {(event) => {
                                              if (event.target.defaultValue.trim().length > 0 ){
                                                  document.getElementById('fullNameGroup-label').classList.add('isFilled');
                                              } else {
                                                  document.getElementById('fullNameGroup-label').classList.remove('isFilled');
                                              }
                                          }}
                            />
                            <InputControl name="email" label="Adres e-mail" className={'abs'} id={'emailGroup'}
                                          onBlur = {(event) => {
                                              if (event.target.defaultValue.trim().length > 0 ){
                                                  document.getElementById('emailGroup-label').classList.add('isFilled');
                                              } else {
                                                  document.getElementById('emailGroup-label').classList.remove('isFilled');
                                              }
                                          }}/>
                            <InputControl name="phone" label="Nr telefonu" className={'abs phone'} id={'phoneGroup'}
                                          onBlur = {(event) => {
                                              if (event.target.defaultValue.trim().length > 0 ){
                                                  document.getElementById('phoneGroup-label').classList.add('isFilled');
                                              } else {
                                                  document.getElementById('phoneGroup-label').classList.remove('isFilled');
                                              }
                                          }}/>
                            <div className="chakra-form-control" role="group">
                                <Box display={'flex'} alignItems={'stretch'}>
                                    <Field type="checkbox" style={{ mr: '10px'}} name="agreement" id="agreement" />
                                    <label htmlFor="agreement" style={{ fontSize: '11px', display: 'flex', alignItems: 'baseline' }}>
                                        <Box as={'span'} color = {'#9BCD66'} fontWeight = {'bold'} ml={'5px'} mr={'5px'} fontSize={'7px'}>■</Box>
                                        Zapoznałem/am się z Polityką Prywatności oraz klauzulą informacyjną RODO</label>
                                </Box>
                                <ErrorMessage style={{fontSize:'11px', color:'#E53E3E'}} id="agreementGroup-feedback" name="agreement" component="div" className="chakra-form__error-message" />
                            </div>
                        <ButtonGroup position={{base:'relative', md:'absolute'}} bottom = {{base: 'auto', md: '30px'}} right = {{ base: 'auto', md: '60px'}} width={'190px'}>
                                <SubmitButton
                                        bg='#008F47'
                                        borderRadius='0'
                                        color='white'
                                        w='100%'
                                        p='20px 30px 20px 30px'
                                        h='auto'
                                        fontSize='13px'
                                        fontWeight='bold'
                                        textTransform='uppercase'
                                        letterSpacing = '2px'
                                        maxW='190px'
                                        justifyContent='flex-start'
                                        textAlign='left'
                                        m= '0'
                                        display={{base: 'block', md: 'flex'}}
                                        _active={{
                                            textDecoration: 'none',
                                            bg: '#008F47'
                                        }}
                                        _focus={{
                                            textDecoration: 'none',
                                            bg: '#008F47'
                                        }}
                                        _hover={{
                                            textDecoration: 'none',
                                            bg: '#008F47'
                                        }}
                                        sx = {{
                                            '&:hover>div' : {
                                                pr:'0'
                                            }
                                        }}
                                >
                                    Aplikuj
                                    <Box position='absolute'
                                         bottom='23px'
                                         right='15px'
                                         transition = 'all 0.3s'
                                         p='0 20px 0 0'
                                    >
                                        <Img src={arrow} alt='Arrow right' w={'30px'}/>
                                    </Box>
                                </SubmitButton>
                            </ButtonGroup>
                    </VStack>
                </Box>
            )}
        </Formik>
    );
};

export default JobForm;