import * as React from "react"
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
    Image, Img, Link, Heading,
} from "@chakra-ui/react";
import JobForm from "./jobForm";
import arrow from "/src/images/arrow.svg"

export default function JobModal(props) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
            <>
                <Modal isOpen={isOpen} onClose={onClose} bg='red'>
                    <ModalOverlay bg='#444444' />
                    <ModalContent borderRadius = '0' p={{ base: '10px 10px 50px 10px ',md: '38px'}} maxW='675px' w={'100%'} m={{base: '10px', md: '50px'}} position='relative'
                        _before = {{
                            content:'" "',
                            w: '18px',
                            height: '18px',
                            background : '#008F47',
                            display: 'block',
                            position: 'absolute',
                            right: '18px',
                            top:'0',
                        }}
                          _after = {{
                              content:'" "',
                              w: '18px',
                              height: '18px',
                              background : '#008F47',
                              display: 'block',
                              position: 'absolute',
                              right: '0',
                              top:'18px',
                          }}
                    >
                        <ModalHeader fontSize='42px' fontWeight='bold'>
                            <Heading as={'h2'} fontSize={{base: '34px', md: '42px'}} fontFamily ={'Montserrat'} textTransform='uppercase'>Wypełnij formularz</Heading>
                            <Heading as={'h3'} fontSize= '22px' fontFamily ={'Montserrat'} textTransform='uppercase'>i aplikuj na stanowisko</Heading>
                            <Box display={'flex'} mt={'40px'} alignItems={'center'}>
                                <Image height={'10px'} style={{ height: '10px', display : 'inline-block', marginRight: '30px'}} src={props.icon} alt='icon'/>
                                <Box fontSize={'15px'} fontWeight={'bold'}>{props.job}</Box>
                            </Box>
                        </ModalHeader>
                        <ModalCloseButton
                            bg='#9BCD66'
                            color='white'
                            size='lg'
                            borderRadius='0'
                            right={{base: '18px', md:'-22px'}}
                            top={{base: '18px', md: '-40px'}}
                            _focus = {{
                                outline: 'none'
                            }}
                            _hover = {{
                                bg : '#008F47',
                                color: 'white'
                            }}
                        />
                        <ModalBody>
                             <JobForm job={props.job}/>
                        </ModalBody>
                        <ModalFooter display={"flex"} justifyContent={'space-between'}>
                            <Box display={"flex"} justifyContent={'flex-start'} fontSize={'11px'} id={'required'}>
                                <Box color = {'#9BCD66'} fontSize='7px' fontWeight = {'bold'} mr={'20px'}>■</Box>
                                <Box color= {'#323538'}>Pola wymagane</Box>
                            </Box>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <Box data-sal="slide-up" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease">
                     <Link as='button' bg='#008F47'
                          borderRadius='0'
                          color='white'
                          w='100%'
                          p='20px 30px 20px 30px'
                          h='auto'
                          fontSize='13px'
                          fontWeight='bold'
                          textTransform='uppercase'
                          letterSpacing = '2px'
                          maxW='190px'
                          position = 'relative'
                          justifyContent='flex-start'
                          textAlign='left'
                          m={{base:'50px auto',md:'55px 0 80px 0'}}
                          display={{base: 'block', md: 'flex'}}
                          onClick={onOpen}
                          _active={{
                              textDecoration: 'none',
                              bg: '#008F47'
                          }}
                          _focus={{
                              textDecoration: 'none',
                              bg: '#008F47'
                          }}
                          _hover={{
                              textDecoration: 'none',
                              bg: '#008F47'
                          }}
                          sx = {{
                              '&:hover>div' : {
                                  pr:'0'
                              }
                          }}
                    >Aplikuj
                        <Box position='absolute'
                             as = 'span'
                             display={'block'}
                             bottom='23px'
                             right='15px'
                             transition = 'all 0.3s'
                             p='0 20px 0 0'
                        >
                            <Img src={arrow} alt='Arrow right' w={'30px'}/>
                        </Box>
                    </Link>
                </Box>
            </>
    )
}