import {Box, Heading} from "@chakra-ui/react";
import * as React from "react";

export default function JobAcfBox(props){

    const job = props.job;

    return (
        <>
            {Object.values(job).map((node,i) => (
                <Box key={i} className={'b'+i}>
                    <Heading as={'h2'} dangerouslySetInnerHTML={{__html: node.header}}/>
                    <Box dangerouslySetInnerHTML={{__html: node.description}} />
                </Box>
            ))}
        </>
    );
}