import * as React from "react"
import {graphql} from "gatsby"
import {Box, Heading, Img, Link,Breadcrumb, BreadcrumbItem, BreadcrumbLink} from "@chakra-ui/react";
import HeroShort from "../components/heroShort/hero";
import arrow from "../../images/arrow.svg";
import send from '/src/images/send.svg'
import JobModal from "../components/career/jobModal/jobModal";
import Stripes from "../components/common/stripes";
import Letter from "../components/common/letter";
import JobAcfBox from "../components/career/jobAcfBox";
import PageBreadcrump from "../components/page/page.breadcrump";

export default function html(props) {
	const job = props.job
	const page = props.page
	const letter = job.title.charAt(0).toUpperCase();

	return (
		<>
			
			<HeroShort page={page} job={job} subtitle={'Oferta pracy'} target={'first'}/>
			<Box p={{ base: '20px 5% 20px 5%', md:'40px 10% 40px 10%',lg:'120px 15% 50px 15%'}}
				 id={'first'}
				 position={'relative'}
				 overflow={'hidden'}>
				<Box mt={'30px'} ml={{base: 0, md: '8%'}} mb={'48px'} fontSize={'11px'} color={'#32353833'} fontWeight={'semibold'} zIndex={'2'}>
					<Breadcrumb>
						<BreadcrumbItem>
							<BreadcrumbLink href={'/'}>Home</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbItem key='1'>
							<BreadcrumbLink href={'/kariera/'}>Kariera</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbItem isCurrentPage>
							<BreadcrumbLink href='#'>{job.title}</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb>
				</Box>
				<Letter letter={letter} right= {{base: '-40%', md: "-10%"}} top={'30%'}/>
				<Stripes z0='-1' z2={'-1'} clickThrough={'true'}/>
				<Box display='flex' flexWrap={{base:'wrap',md:'nowrap'}} maxW={'1120px'} m={'auto'}>
					<Box w={{base:'100%',md:'50%'}} pl={{base: '0', md: '8%'}} pr={{base:'0',md:'80px'}} borderRight={{base:'0',md:'1px solid #33353833'}}>
						<Box sx={{
							'& h2' : { mt: '80px', mb: '40px', fontSize : '15px', fontWeight:'bold', maxW:'80%', fontFamily: 'Montserrat'},
							'& .b0 h2' : { mt: '10px'},
							'& ul' : { listStyleType: "none",  ml:'2em'},
							'& li' : { mb: '30px', fontWeight: 'normal'},
							'& li:before' : {	content: '"■"',
												color: '#9BCD66',
												fontWeight: 'bold',
												display: 'inline-block',
												w: '1em',
												ml: '-2em',
												pr: '2em'},

							}}
						>
							<JobAcfBox job={job.careerJobOptions} />
						</Box>
					</Box>
					<Box w={{base:'100%',md:'50%'}} pl={{base:'0',md:'80px'}} mt={{base:'50px',md:'0'}}>
						<Heading as='h2' mb='43px' fontSize={{base: '34px', md: '42px'}} fontFamily="'essonnes-headline', serif" fontWeight='bold' maxW={{base:'100%',md:'80%'}}>
							Zainteresowała Cię ta oferta pracy?
						</Heading>
						<Box data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease">
							<strong>Masz dodatkowe pytania?</strong>
							<Box fontWeight={'semibold'} fontSize={'14px'} lineHeight={'28px'} mt={'20px'}>
								<Box>Skontaktuj się z nami: <Link href={'mailto:rekrutacja@cyrekfinance.com'} color={'#9BCD66'}>rekrutacja@cyrekfinance.com</Link>.</Box>
								<Box mt={'20px'}>Jeśli chcesz wziąć udział w rekrutacji wypełnij formularz, który pokaże się po kliknięciu przycisku Aplikuj.</Box>
							</Box>
						</Box>

						<JobModal job={job.title} icon={send}/>

						<Link
							href = '/kariera/'
							size="xl"
							fontWeight="bold"
							position="relative"
							transition = 'all 0.3s'
							letterSpacing = '5px'
							lineHeight = '40px'
							display = 'inline-block'
							pl = '80px'
							textTransform = 'uppercase'
							_hover={{
								textDecoration: 'none',
							}}
							data-sal="slide-up" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease"
							sx={{ '&:hover img' : { left: '0' } }}
						>
							<Img style={{ width: '42px',transform:'rotate(180deg)',transition : 'all 0.3s'}} alt={'arrow_left'} pos='absolute' top='12px' left='20px' src={arrow}/>
							Powrót do ofert
						</Link>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export const query = graphql`
  fragment JobDefaultInformation on WpJob {
    content
	  careerJobOptions {
		  scopeOfWork {
			  description
			  header
		  } 
		  ourExpectations{
			  description
			  header
		  }
		  weOffer{
			  description
			  header
		  }
		  experience {
			  description
			  header
		  }
		  placeOfWork {
			  description
			  header
		  }
		  salary {
			  description
			  header
		  }
	  }
  }
`